import * as React from "react";
import Heading from "@atoms/Heading";
import LocationSection from "./LocationSection";
import Image from "next/image";
import HeadingNew from "@atoms/HeadingNew";
const ContactSection = ({ locationList }) => {
    return (
        <section className={`relative bg-green-400 overflow-hidden`}>
            <div className="-mb-2.5 md:hidden block absolute bottom-[-26px] right-2">
                <Image
                    src="/assets/images/sunshine/ContactUsBottom.svg"
                    width={400}
                    height={106}
                />
            </div>
            <div className=" max-w-[1180px] mx-auto">
                <HeadingNew
                    type="h2"
                    fontSize="text-xl lg:text-[40px]"
                    fontWeight="font-semibold "
                    className=" leading-normal text-center py-8 lg:pt-[86px] lg:pb-[68px] "
                >
                    Contact Us
                </HeadingNew>

                <div className="flex gap-6 mb:gap-12 items-center flex-wrap justify-center mb-28 lg:mb-60">
                    <LocationSection locationList={locationList} />
                </div>

                <div className="flex justify-between items-end lg:block hidden ">
                    <div className="max-w-[100px] md:max-w-max absolute -bottom-24 left-4 ">
                        <Image
                            src="/assets/images/sunshine/vector-leaf-1.png"
                            width={206}
                            height={225}
                        />
                    </div>

                    <div className="max-w-[100px] md:max-w-max absolute -bottom-6 right-24 ">
                        <Image
                            src="/assets/images/sunshine/Assetgiraffe.svg"
                            width={141}
                            height={252}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
